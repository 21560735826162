@tailwind base;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 198, 85%, 47%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 0, 0%, 87%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    /* text color */
    --dark: 0, 0%, 14%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }

  /* taken from tailwind preflight/reset css  */
  *,
  ::before,
  ::after {
    border-width: 0;
    border-style: solid;
  }

  [type='button'],
  [type='reset'],
  [type='submit'],
  button {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }

  button {
    cursor: pointer;
  }

  @media (max-width: 480px) {
    .adyen-checkout__payment-method__brands {
      @apply flex-nowrap;
    }

    .adyen-checkout__payment-method__header
      .adyen-checkout__payment-method__image__wrapper--outline {
      @apply !w-10 flex items-center justify-center [&_img]:!transform-none;
    }

    .adyen-checkout__payment-method__brands
      .adyen-checkout__payment-method__image__wrapper--outline:nth-of-type(
        odd
      ) {
      @apply hidden;
    }
  }
}

@tailwind components;
@tailwind utilities;

.PromotionPlan {
  opacity: 1;
  transform: scale(1);
  transition:
    transform 500ms ease-in-out,
    opacity 500ms ease-in-out;

  @starting-style {
    opacity: 0;
    transform: scale(0);
  }
}

@layer components {
  /* MINDFULNESS TEXT SECTION */

  .TextSection--ElementInnerContent a {
    @apply text-primary font-medium;
  }

  .TextSection--ElementInnerContent p:first-of-type,
  .TextSection--ElementInnerContent ul:first-of-type {
    margin-top: 0;
  }

  /* ==== */

  .TextSection--BorderElement {
    position: relative;
    border: 1px solid black;
    border-radius: 50px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    padding: 30px 35px;
  }

  .TextSection--BorderElement .TextSection--ElementContent {
    position: relative;
    z-index: 2;
  }

  .TextSection--BorderElement:before {
    content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    display: block;
    width: 80px;
    height: 80px;
    z-index: 0;

    @apply bg-neutral-50;
  }

  .TextSection--BorderElement:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: -2px;
    display: block;
    width: 80px;
    height: 80px;
    z-index: 0;

    @apply bg-neutral-50;
  }

  /* MINDFULNESS TEXT SECTION */

  /*  */
  .Section--TwoColumn {
    h1,
    h2,
    h3 {
      @apply m-0 mb-2;
    }
  }
  /*  */
}
